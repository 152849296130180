body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: EMprintRegular,Anuphan,HelveticaNeue,Helvetica,Arial,sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.em-c-skeleton--pulse {
  animation: skeletonPulse 1s infinite;
}
.em-c-skeleton {
  background: #e5e5e5;
}